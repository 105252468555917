<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Servicios atendidos</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pt-0">
                    <v-card>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="2">
                                    <v-menu
                                        v-model="menuFrom"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="filters.from"
                                                label="Desde"
                                                readonly
                                                clearable
                                                v-on="on"
                                                outlined
                                                hide-details                         
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filters.from" 
                                            @input="menuFrom = false"
                                            no-title
                                            locale="es">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="2">
                                    <v-menu
                                        v-model="menuUntil"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="filters.until"
                                                label="Hasta"
                                                readonly
                                                clearable
                                                v-on="on"
                                                outlined
                                                hide-details                         
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filters.until" 
                                            @input="menuUntil = false"
                                            no-title
                                            locale="es">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="3">
                                    <v-autocomplete
                                        v-model="filters.service_id"
                                        :items="services_api"
                                        label="Servicio"
                                        outlined
                                        item-text="name"
                                        item-value="id"
                                        clearable
                                        hide-details="auto"
                                        :loading="isLoading"
                                        :search-input.sync="search_service"
                                        hide-no-data
                                        hide-selected
                                        placeholder="Ingrese el servicio a buscar"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="3">
                                    <v-autocomplete                             
                                        v-model="filters.worker_id"
                                        :items="workers"
                                        label="Seleccione un trabajador"
                                        hide-details
                                        outlined
                                        item-text="fullname"
                                        item-value="id"
                                        clearable
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn color="blue"
                                        dark
                                        @click="searchServices">
                                        Buscar
                                    </v-btn>
                                    <v-btn color="green"
                                        dark
                                        class="ml-1"
                                        :loading="processing"
                                        @click="downloadServices">
                                        Descargar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>                
            </v-row>
            <v-row>
                <v-col cols="12" class="pt-1">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="services"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="57vh"
                            fixed-header
                        >
                            <template v-slot:top>
                                <span  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">Todas los servicios atendidos</span>
                                <v-divider></v-divider>
                            </template>

                            <template v-slot:item.sale_date="{item}">
                                <span>{{ item.sale_date | moment('DD/MM/YYYY') }}</span>
                            </template>

                            <template v-slot:item.subtotal="{item}">
                                <span>{{ item.subtotal | currency('S/') }}</span>
                            </template>

                            <template v-slot:item.discount_value="{item}">
                                <span v-if="item.discount_value">
                                    {{ item.discount_value | currency('S/') }}
                                </span>
                                <span v-else>
                                    S/0.00
                                </span>
                            </template>

                            <template v-slot:item.total="{item}">
                                <span v-if="item.discount_value">
                                    {{ (item.subtotal - item.discount_value) | currency('S/') }}
                                </span>
                                <span v-else>
                                    {{ item.subtotal | currency('S/') }}
                                </span>
                            </template>

                            <template v-slot:item.actions="{item}">
                                <v-icon size="14"
                                    color="blue"
                                    @click="handleViewDetail(item)">
                                    fas fa-bars
                                </v-icon>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <service-detail ref="serviceDetail"></service-detail>
    </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import OfficeLogin from '../../../components/auth/OfficeLogin';

import ServicesApi from '../../../apis/Services';
import ServiceDetail from '../../../components/reports/ServiceDetail';

export default {
    name: 'AttendedServices',
    components: {
        OfficeLogin,
        ServiceDetail
    },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Reportes',
                    disabled: true,
                    href: '',
                }
            ],
            headers: [
                { text: 'FECHA', value: 'sale_date', sortable: false, width: 100 },
                { text: 'SERVICIO', value: 'description', sortable: false, width: 310 },
                { text: 'TRABAJADOR', value: 'fullname', sortable: false },
                { text: 'CANTIDAD', value: 'quantity', sortable: false, align: 'end' },
                { text: 'SUBTOTAL', value: 'subtotal', sortable: false, align: 'end' },
                { text: 'DESCUENTO', value: 'discount_value', sortable: false, align: 'end' },
                { text: 'TOTAL', value: 'total', sortable: false, align: 'end' },
                { text: '', value: 'actions', sortable: false, align: 'center' },
            ],
            processing: false,
            menuFrom: false,
            menuUntil: false,
            filters: {
                from: this.$moment().format('YYYY-MM-DD'),
                until: this.$moment().format('YYYY-MM-DD'),
                worker_id: null,
                service_id: null
            },
            isLoading: false,
            search_service: null,
            services_api: [],
        }
    },
    computed: {
        ...mapState('authentication', [
            'office_id',
        ]),
        ...mapState('employees', [
            'workers'
        ]),
        ...mapState('report-services', [
            'services',
            'file_name'
        ])
    },
    watch: {
        async search_service (value) {
            if (value == null || value == undefined || value.length == 0) {
                return;
            }

            if (this.services_api.length > 0) {
                return;
            }

            if (this.isLoading) return

            this.isLoading = true

            let filters = {
                name: value
            }
            ServicesApi.get_all(filters)
                .then( response => {
                    this.services_api = response.data;
                })
                .catch ( error => {
                    console.log(error);
                })
                .finally( () => (this.isLoading = false));
        },
    },
    methods: {
        ...mapActions('employees', [
            'getWorkers'
        ]),
        ...mapActions('report-services', [
            'getAttendedServices',
            'setServices',
            'downloadAttendedServices'
        ]),
        searchServices () {
            this.getAttendedServices(this.filters);
        },
        async downloadServices () {
            this.processing = true;
            let filters_data = Object.assign({}, this.filters);
            await this.downloadAttendedServices(filters_data);
            this.processing = false;
            if (this.file_name) {
                let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_name;
                window.open(url);
            } 
        },
        handleViewDetail (service) {
            console.log(service);
            this.$refs.serviceDetail.show(service);
        }
    },
    created () {
        this.searchServices();
        this.getWorkers({
            office_id: this.office_id
        })
    },
    destroyed () {
        this.setServices([]);
    }
}
</script>

<style scoped>

</style>