<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="900"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>DETALLE DEL SERVICIO ATENDIDO</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="1" class="pt-2 pb-2">
                        <span class="subtitle-2">Fecha:</span>
                    </v-col>
                    <v-col cols="9" class="pt-2 pb-2">
                        <span>{{ form.sale_date | moment('DD/MM/YYYY') }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="pt-2 pb-2">
                        <span class="subtitle-2">Servicio:</span>
                    </v-col>
                    <v-col cols="9" class="pt-2 pb-2">
                        <span>{{ form.service_name }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="pt-2 pb-2">
                        <span class="subtitle-2">Trabajador:</span>
                    </v-col>
                    <v-col cols="9" class="pt-2 pb-2">
                        <span>{{ form.employee }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-data-table
                                :headers="headers"
                                :items="services"
                                :items-per-page="10"
                                class="elevation-1 w-table"
                                height="57vh"
                                fixed-header
                            >
                                <template v-slot:item.created_at="{item}">
                                    <span>{{ item.created_at | moment('DD/MM/YYYY') }}</span>
                                </template>
                                <template v-slot:item.hour_sale="{item}">
                                    <span>{{ item.created_at | moment('HH:mm') }}</span>
                                </template>

                                <template v-slot:item.subtotal="{item}">
                                    <span>{{ item.subtotal | currency('S/') }}</span>
                                </template>

                                <template v-slot:item.discount_value="{item}">
                                    <span v-if="item.discount_value">
                                        {{ item.discount_value | currency('S/') }}
                                    </span>
                                    <span v-else>
                                        S/0.00
                                    </span>
                                </template>

                                <template v-slot:item.total="{item}">
                                    <span v-if="item.discount_value">
                                        {{ (item.subtotal - item.discount_value) | currency('S/') }}
                                    </span>
                                    <span v-else>
                                        {{ item.subtotal | currency('S/') }}
                                    </span>
                                </template>

                                <template v-slot:item.is_bonus="{item}">
                                    <span v-if="item.is_bonus">SI</span>
                                </template>


                            </v-data-table>

                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </div>
    </v-navigation-drawer>
</template>

<script>
import ReportApi from '../../apis/Reports';

export default {
    name: 'ServiceDetail',
    data () {
        return {
            drawer: false,
            form: {
                sale_date: null,
                service_name: null,
                employee: null
            },
            services: [],
            headers: [
                { text: 'FECHA', value: 'created_at', sortable: false, width: 100 },
                { text: 'HORA', value: 'hour_sale', sortable: false, width: 70 },
                { text: 'CANTIDAD', value: 'quantity', sortable: false, align: 'end', width: 100 },
                { text: 'SUBTOTAL', value: 'subtotal', sortable: false, align: 'end', width: 120 },
                { text: 'DESCUENTO', value: 'discount_value', sortable: false, align: 'end', width: 110 },
                { text: 'TOTAL', value: 'total', sortable: false, align: 'end', width: 120 },
                { text: 'GRATUITO', value: 'is_bonus', sortable: false, align: 'center' }
            ],
        }
    },
    methods: {
        show (service) {
            this.clearForm();
            this.form.sale_date = service.sale_date;
            this.form.service_name = service.description;
            this.form.employee = service.fullname;
            this.drawer = !this.drawer;
            this.getDetailService(service);
        },
        clearForm () {
            this.form.sale_date = null;
            this.form.service_name = null;
            this.form.employee = null;
            this.services = [];
        },
        getDetailService (service) {
            let filters = {
                from: service.sale_date,
                until: service.sale_date,
                worker_id: service.worker_id,
                service_id: service.service_id
            }
            ReportApi.getAttendedServicesDetail(filters)
                .then( response => {
                    this.services = response.data;
                })
                .catch ( error => {
                    console.log(error);
                });
        }
    }
}
</script>

<style scoped>

</style>